/* global AssinadorOtp */

/**
 * Classe responsável por realizar a assinatura de PDF.
 *
 * @type {AssinaturaPdf}
 */
class AssinaturaPdf extends Assinatura {

    /**
     * Método construtor da classe.
     *
     * @param {object} certificado
     * @param {string} contentPdfOriginal
     * @param {number} modoOperacao
     * @param {number} tipoAssinatura
     * @param {number} politicaAssinatura
     * @param {number} registroAssinar
     * @return {AssinaturaPdf}
     */
    constructor(certificado, contentPdfOriginal, modoOperacao, tipoAssinatura, politicaAssinatura, registroAssinar) {
        super();

        this.certificado = certificado;
        this.contentPdfOriginal = contentPdfOriginal;
        this.modoOperacao = modoOperacao;
        this.tipoAssinatura = tipoAssinatura;
        this.politicaAssinatura = politicaAssinatura;
        this.registroAssinar = registroAssinar;

        this.senhaOtp = document.querySelector('#senha_otp');
        this.cpfProfissionalLogado = document.querySelector('#username');
        this.fieldSetSenhaOtp = document.querySelector('#fieldset-field_04');

    }

    /**
     * Método responsável por assinar o documento PDF
     *
     * @returns {void}
     */
    async assina() {
        const dadosAssinatura = new DadosAssinatura(
            this.certificado,
            this.modoOperacao,
            this.tipoAssinatura,
            this.politicaAssinatura,
            this.cpfProfissionalLogado.value
        );

        dadosAssinatura.isValid();

        const resultSign = await this.assinadorAdapter.signPdf(dadosAssinatura, this.registroAssinar);

        const statusComErro = Utils.getSituacaoAssinaturaId('Com erro ao assinar');
        const statusAssinado = Utils.getSituacaoAssinaturaId('Assinado');
        const statusProcessoFinalizado = Utils.getSituacaoAssinaturaId('Processo finalizado');
    
        let statusSign = statusComErro;
        let numberIterations = 0;
        let resultStatusSignature = null;
        let resultValidSignature = null;

        if (resultSign.success) {
            do {
                resultStatusSignature = await this.assinadorAdapter.statusPdf(this.registroAssinar);

                statusSign = resultStatusSignature && resultStatusSignature.status_id !== undefined ? resultStatusSignature.status_id : statusSign;

                numberIterations++;
            } while (statusSign !== statusAssinado && statusSign !== statusProcessoFinalizado && numberIterations < 4);

            if (statusSign !== statusAssinado && statusSign !== statusProcessoFinalizado)
                resultValidSignature = await this.assinadorAdapter.validPdf(this.registroAssinar);

        }

        return {
            'resultSign': resultSign,
            'resultStatusSignature': resultStatusSignature,
            'resultValidSignature': resultValidSignature
        };
    }

    factoryMessage(data) {
        return Utils.factoryMessageCertificates(data);
    }
}